@font-face {
    font-family: 'Roboto';
    font-display: auto;
    src: url("../fonts/Roboto-Thin.woff2") format("woff2"), url("../fonts/Roboto-Thin.woff") format("woff");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    font-display: auto;
    src: url("../fonts/Roboto-ThinItalic.woff2") format("woff2"), url("../fonts/Roboto-ThinItalic.woff") format("woff");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    font-display: auto;
    src: url("../fonts/Roboto-Light.woff2") format("woff2"), url("../fonts/Roboto-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    font-display: auto;
    src: url("../fonts/Roboto-LightItalic.woff2") format("woff2"), url("../fonts/Roboto-LightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    font-display: auto;
    src: url("../fonts/Roboto-Regular.woff2") format("woff2"), url("../fonts/Roboto-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    font-display: auto;
    src: url("../fonts/Roboto-RegularItalic.woff2") format("woff2"), url("../fonts/Roboto-RegularItalic.woff") format("woff");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    font-display: auto;
    src: url("../fonts/Roboto-Medium.woff2") format("woff2"), url("../fonts/Roboto-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    font-display: auto;
    src: url("../fonts/Roboto-MediumItalic.woff2") format("woff2"), url("../fonts/Roboto-MediumItalic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    font-display: auto;
    src: url("../fonts/Roboto-Bold.woff2") format("woff2"), url("../fonts/Roboto-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    font-display: auto;
    src: url("../fonts/Roboto-BoldItalic.woff2") format("woff2"), url("../fonts/Roboto-BoldItalic.woff") format("woff");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    font-display: auto;
    src: url("../fonts/Roboto-Black.woff2") format("woff2"), url("../fonts/Roboto-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    font-display: auto;
    src: url("../fonts/Roboto-BlackItalic.woff2") format("woff2"), url("../fonts/Roboto-BlackItalic.woff") format("woff");
    font-weight: 900;
    font-style: italic;
}
