
.section-map {
	#mapid {
		width: 100%;
		height: 400px;
	}

	#popup {
		display: none;

		&.show {
			display: block;
			background: white;
		}
	}
}

.section-keywords {
	font-size: calculateRem(13);
	line-height: 1.75;

	strong {
		color: $green-3;
	}
}

.section-vcard {

	.left-bar {
		@include make-col-ready();

		flex: 0 0 33%;
	    max-width: 33%;
	    
	    &--full-width {
			flex: 0 0 100% !important;
		    max-width: 100% !important;	
		    display: flex;
		    @include make-row();

		    .vcard-logo {
				@include make-col-ready();

				flex: 0 0 50%;
			    max-width: 50%;
			    display: flex;
			    justify-content: center;
			    padding-left: 1rem;
			    padding-right: 1rem;

			    @include media-breakpoint-down(sm) {
				    margin-left: 15px;
				    margin-right: 15px;
			    	flex: 0 0 100%;
			    	max-width: 100%;
			    }
		    }

		    .v_box {
				@include make-col-ready();

				flex: 0 0 50%;
			    max-width: 50%;
			    display: flex;
			    justify-content: center;
			    padding-left: 1rem;
			    padding-right: 1rem;

			    @include media-breakpoint-down(sm) {
			    	flex: 0 0 100%;
			    	max-width: 100%;
			    	justify-content: flex-start;
				    margin-left: 15px;
				    margin-right: 15px;
			    }
		    }
	    }

		

		@media (max-width: 1366px) {
			flex: 0 0 40%;
		    max-width: 40%;
		}

	    @include media-breakpoint-down(sm) {
			flex: 0 0 100%;
		    max-width: 100%;	    	
	    }
	}

	.right-bar {
		@include make-col-ready();

		flex: 0 0 66%;
	    max-width: 66%;

		@media (max-width: 1366px) {
			flex: 0 0 60%;
			max-width: 60%;
		}

	    @include media-breakpoint-down(sm) {
			flex: 0 0 100%;
		    max-width: 100%;	    	
	    }
	}

	#vcard_stempel_najfirma {
		// position: absolute;
		// top: -50%;
		margin-bottom: 3rem;
		text-align: center;
	}


	.subheading {
		color: $green-3;
		font-weight: bold;
		font-size: calculateRem(21);
		margin-top: 1rem;
	}

	&__desc {
		color: $black;
		font-size: calculateRem(14);	

		a {
			color: $green-3;

			&:hover, &:focus {
				color: $black;
				text-decoration: none;
			}
		}
	}

	.additional-info-table {
	}

	.form {
		label {
			font-size: calculateRem(14);
			font-weight: bold;
			padding: 0 .5rem;
		}

		.form-control {
			padding: .75rem .75rem;
			border-radius: 8px;
		}

		.form-group {
			margin-bottom: 1rem;
		}

		.form-submit {
			@extend .btn;

			color: $white;
			font-weight: 500;
			font-size: calculateRem(16);
			background: $green-1;
			padding: 1.063rem 1.5rem 1.063rem 1rem;
			position: relative;
			border-radius: 5px;
			// margin-left: 1.25rem;
			position: relative;
		    display: flex;
		    align-items: center;
		    justify-content: space-between;
		    margin-left: auto;

			&:hover,&:focus {
				text-decoration: none;
				color: $white;
				background: $black;
			}

			&:after {
				content: ' ';
				display: block;
				width: 100%;
				height: 5px;
				position: absolute;
				left: 0;
				bottom: 0;
				background: $white;
				opacity: .1;
			}

			.fas {
				// margin-right: auto;
				margin-left: 1rem;
				
				@include media-breakpoint-up(md) {
					margin-left: 2.5rem;
				}
			}
		}

        .form-rules {
            color: #a3a3a3;
            font-size: calculateRem(13);
            position: relative;

            input[type=checkbox] {
                display: none;
            }

            input[type=checkbox] + label {
                padding-left: 60px;
                font-weight: 400;
                
                a {
					color: $green-1;
					text-decoration: underline;
                }
            }

            input[type=checkbox] + label:before {
                left: 1rem;
                position: absolute;
                content: "";
                display: block;
                float: left;
                width: 28px;
                height: 28px;
                vertical-align: middle;
                margin-right: 8px;
                background-color: transparent;
                border: 1px solid $grey-1;
                border-radius: 8px;
                margin-right: 15px;
                top: 50%;
                transform: translateY(-50%);
            }

            input[type=checkbox]:checked + label:before {
                content: "\2714";
                color: $green-1;
                font-size: calculateRem(28);
                text-align: center;
                line-height: 28px;
            }
        }
	}

	.vcard-logo {
		padding: 2rem;
		border: 1px solid $grey-1;
		margin-bottom: 3rem;
		width: 100%;

		img {
			width: 100%;
			height: auto;
		}
	}

	.g-recaptcha {
		@media (min-width: 769px) and (max-width: 1280px) {
			transform: scale(0.7);
		}
	}
}

.section-gallery {
	padding-bottom: 1rem;

	.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
		color: $green-1;
		font-size: 40px;
	}

	.owl-theme .owl-nav [class*=owl-]:hover {
		background: transparent;
	}

	.owl-theme .owl-nav [class*=owl-]:focus {
		outline: none;
	}

	.company-gallery {
		// list-style-type: none;
		// display: flex;
		// justify-content: center;
		// align-items: center;
		padding-bottom: 2rem;



		&__item {
			padding: 1rem;
			box-sizing: border-box;
			text-align: center;	

			img {
			    display: inline-block;
			    height: auto;
			    width: auto;
			    max-width: 100%;
			}
		}

	}
}

.vcard-contact-list {
	padding: 0 0 0 0;
	margin: 0;
	list-style-type: none;
	position: relative;

	&--column {
		display: flex;
		flex-direction: column;

		.vcard-contact-list__item {
			padding-left: 2rem;
			margin-bottom: 1rem;
			font-size: calculateRem(14);
			// width: 100%;
		    // flex: 0 0 33.33%;
		    // max-width: 33.33%;
		    // position: relative;


			&:before {
				left: 0rem;
				transform: scale(.8);
			}
		}
	}

	a {
		color: inherit;

		&:hover, &:focus {
			text-decoration: none;
		}
	}

	&__item {
		padding-bottom: 0.25rem;
		padding-left: 3.5rem;
		position: relative;
		margin-bottom: 2rem;
		font-size: calculateRem(16);
		overflow: hidden;
	    overflow-wrap: break-word;
		
		@media (max-width: 1366px) {
		    font-size: calculateRem(14);
		}

		&:before {
			content: ' ';
			display: block;
			// width: 19px;
			// height: 17px;
			position: absolute;
			left: 1rem;
			top: 0px;
			// background-image: url("../images/address-green-icon.png");
		}
		
		&:not(:first-child) {
			
		}
		
		&:not(:last-child) {
			
		}

		span {
			display: block;
		}

		&--address {

			&:before {
				width: 18px;
				height: 24px;
				background-image: url("../images/marker-green-icon.png");
			}
		}

		&--phone {

			&:before {
				width: 19px;
				height: 19px;
				background-image: url("../images/phone-2-green-icon.png");
			}
		}

		&--mail {

			&:before {
				width: 19px;
				height: 19px;
				background-image: url("../images/letter2-green-icon.png");
			}
		}

		&--info {

			&:before {
				width: 19px;
				height: 19px;
				background-image: url("../images/info-green-icon.png");
			}
		}

		&--fax {

			&:before {
				width: 19px;
				height: 19px;
				background-image: url("../images/fax-green-icon.png");
			}
		}

		&--www {

			&:before {
				width: 19px;
				height: 19px;
				background-image: url("../images/click-green-icon.png");
			}
		}

		&--facebook {

			&:before {
				width: 19px;
				height: 19px;
				background-image: url("../images/facebook-green-icon.png");
			}
		}
	}
}

.section-additional-info {
	padding-top: 2rem;
	padding-bottom: 2rem;
	font-size: calculateRem(13);
	line-height: 1.75;

	> .container {
		border-top: 1px solid $grey-1;
		padding-top: 2rem;
	}


	strong {
		color: $green-3;
	}

	a {
		color: inherit;

		&:hover, &:focus {
			color: $green-3;
		}
	}

	.edit-button {
			@extend .btn;

			color: $white;
			font-weight: 500;
			font-size: calculateRem(16);
			background: $green-1;
			padding: 1.063rem 1.5rem 1.063rem 1rem;
			position: relative;
			border-radius: 5px;
			// margin-left: 1.25rem;
			position: relative;
		    display: inline-flex;
		    align-items: center;
		    justify-content: space-between;
		    margin-left: auto;

			&:hover,&:focus {
				text-decoration: none;
				color: $white;
				background: $black;
			}

			&:after {
				content: ' ';
				display: block;
				width: 100%;
				height: 5px;
				position: absolute;
				left: 0;
				bottom: 0;
				background: $white;
				opacity: .1;
			}

			@media (max-width: 576px) {
				margin-bottom: 2rem;
				margin-left: auto;
				margin-right: auto;
			}

			.fas {
				// margin-right: auto;
				margin-left: 1rem;
				
				@include media-breakpoint-up(md) {
					margin-left: 2.5rem;
				}
			}
	}
}