.footer {
	padding: 3rem 0;
	color: $white;
	background: #1d1d1d;
	font-size: calculateRem(14);

	@media (max-width: 1024px) {
		padding: 1.5rem 0;
		
		img {
			max-width: 100px;
		}
	}

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}

	.footer-logo {
		margin-right: 1rem;

		@media (max-width: 1366px) {
			max-width: 100px;
		}
	}

	.footer-seperator {
	    background-image: url(../images/footer-seperator.png);
	    width: 9px;
	    height: 88px;
	    background-repeat: no-repeat;
	    display: inline-block;
	    margin: 0 1.5rem;

		@media (max-width: 1366px) {
			margin: 0 1rem;
		}

		@media (max-width: 1024px) {
			display: none;
		}
	}

	.navbar {
		padding-left: 0;
		padding-right: 0;

		@media (max-width: 1024px) {
			margin-left: auto;
		}

		@include media-breakpoint-down(md) {
			margin-top: 1rem;
			margin-bottom: 1rem;
			margin-left: 0;
		}

		.navbar-nav {
			@include media-breakpoint-down(md) {
				// width: 100%;
			}
		}

		.nav-item {
			position: relative;

			&:not(:first-child) {
				&:before {
					content: ' ';
					display: block;
					width: 1px;
					height: 9px;
					position: absolute;
					background-image: url("../images/footer-nav-link-seperator.png");
					background-repeat: no-repeat;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					color: $white;
					
					@include media-breakpoint-down(md) {
						display: none;
					}
				}
			}

			.nav-link {
				color: $white;
				font-size: calculateRem(13);

				@include media-breakpoint-down(md) {
					text-align: center;
				}

				&:hover, &:focus {
					color: $green-1;
					text-decoration: none;
				}
			}
		}
	}

	.copy-text {
		margin-left: 1rem;

		a {
			color: $white;
		}
	}

	.rf-logo {
		margin-left: 1rem;
		margin-right: 1rem;

		@media (max-width: 1366px) {
			max-width: 100px;
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 1rem;
		}
	}
}