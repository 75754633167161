// Grid system
.main {
  @include make-grid-columns($main-sm-columns);
  .sidebar-primary & {
    @include make-grid-columns($main-sm-columns - $sidebar-sm-columns);
  }
}
.sidebar {
  @include make-grid-columns($sidebar-sm-columns);
}
