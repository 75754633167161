// Search form
.search-form {
    @extend .form-inline;
}

.search-form label {
    font-weight: normal;

    @extend .form-group;
}

.search-form .search-field {
    @extend .form-control;
}

.search-form .search-submit {
    @extend .btn;

    @extend .btn-primary;
}

.help-block {
	color: #bfbfbf;

	a {
		color: inherit;
	}
}


#message {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-radius: 18px;
    overflow: hidden;
    position: relative;



    .okform {
        color: $white;
        padding: 2rem;
        background: $green-1;
        box-sizing: border-box;
        display: block;
        margin-bottom: 0;
    }

    .errorform {
        color: $white;
        padding: 2rem;
        background: #ff2828;
        box-sizing: border-box;
        display: block;
        margin-bottom: 0;
    }
}