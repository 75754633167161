.header {
	padding-top: 2rem;
	padding-bottom: 2rem;

	@media (max-width: 768px) {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	@media (min-width: 1330px) {
		.container {
		    max-width: 1300px;
		}
	}

	@media (min-width: 1440px) {
		.container {
		    max-width: 1400px;
		}
	}

	.col-left {
		@include make-col-ready();


		@media (min-width: 1440px) {
		    flex: 0 0 58.3333333333%;
		    max-width: 58.3333333333%;
		    display: flex;
		    align-items: center;
		    flex-direction: row;
		}

		@media (max-width: 1366px) {
		    flex: 0 0 57%;
		    max-width: 57%;
		    display: flex;
		    align-items: center;
		    flex-direction: row;
		}

		@media (max-width: 1280px) {
		    flex: 0 0 58.3333333333%;
		    max-width: 58.3333333333%;
		    display: flex;
		    align-items: center;
		    flex-direction: row;
		}

		@media (max-width: 1024px) {
			flex-direction: column;
		    flex: 0 0 60%;
		    max-width: 60%;
		    justify-content: start;
		}

		@media (max-width: 768px) {
		    flex: 0 0 70%;
		    max-width: 70%;
		    flex-direction: column;
		}

		@media (max-width: 576px) {
			flex-direction: column;
			align-items: center;

		    flex: 0 0 100%;
		    max-width: 100%;	
		}

		// @extend .col;
		// @extend .col-12;
		// @extend .col-md-8;
		
	}

	.col-right {
		@include make-col-ready();

		@media (min-width: 1440px) {
		    flex: 0 0 41.6666666667%;
		    max-width: 41.6666666667%;			
		    justify-content: flex-end;
		    align-items: center;
		    display: flex;
		}

		@media (max-width: 1366px) {
		    flex: 0 0 43%;
		    max-width: 43%;
		    display: flex;
		    align-items: center;
		    justify-content: flex-end;
		}

		@media (max-width: 1280px) {
		    flex: 0 0 41.6666666667%;
		    max-width: 41.6666666667%;			
		    justify-content: flex-end;
		    align-items: center;
		    display: flex;
		}

		@media (max-width: 1024px) {
			flex-direction: row;
		    flex: 0 0 40%;
		    max-width: 40%;		
		}

		@media (max-width: 768px) {
		    flex: 0 0 30%;
		    max-width: 30%;	
		    justify-content: center;
		    align-items: center;
		    display: flex;
		    flex-direction: column;
		}

		@media (max-width: 576px) {
		    flex: 0 0 100%;
		    max-width: 100%;	
		}
	}

	.logo-link {
		float: left;
		display: inline-block;


	}
	
	.logo-image {
		margin: auto;
		max-width: 100%;
		height: auto;
		width: auto;
		flex-shrink: 0;
		align-self: flex-start;

		@media (max-width: 1024px) {
			// max-width: 120px;
		}

		@media (min-width: 1200px) and (max-width: 1280px) {
			max-width: 180px;
		}

		@media (min-width: 1281px) and (max-width: 1366px) {
			max-width: 250px;
		}
		
		@media (min-width: 1280px) {
			// max-width: 100%;
		}
	}

	&-seperator {
		background-image: url("../images/header-seperator.png");
		width: 9px;
		height: 88px;
		background-repeat: no-repeat;
		display: inline-block;
		margin: 0 1.5rem;

		@media (max-width: 1024px) {
			display: none;
		}
	}

	.navbar-nav {
		.nav-link {
			color: $black;
			font-size: calculateRem(16);

			@media (max-width: 1024px) {
				font-size: calculateRem(14);
			}

			&:hover, &:focus {
				color: $green-1;
				text-decoration: none;
			}

		}

		.nav-item {
			padding: 0 1rem;
			


			&:not(:first-child) {
				background-repeat: no-repeat;
				background-image: url("../images/nav-item-seperator.jpg");
				background-position: left center;

			}

			@include media-breakpoint-down(md) {
				background-image: none !important;
				text-align: center;
			}
		}
	}

	.header-button-add {
		color: $white;
		font-weight: 500;
		font-size: calculateRem(16);
		background: $green-1;
		padding: .75rem 1.25rem;
		position: relative;
		border-radius: 5px;
		box-shadow: 0px 0px 15px 5px rgba(127,181,47,.71);
		margin-right: 1rem;

		@media (max-width: 1024px) {
			// font-size: calculateRem(14);
			padding: .5rem;
		}

		@include media-breakpoint-down(md) {
			display: flex;
			margin-top: 2rem;
			margin-bottom: 2rem;
			align-items: center;
			max-width: 80%;
			margin-left: auto;
			margin-right: auto;
		}

		@media (max-width: 576px) {
			margin-top: 1rem;
			margin-bottom: 1rem;
		}

		&:hover,&:focus {
			text-decoration: none;
			color: $white;
			background: $black;
		}

		&:after {
			content: ' ';
			display: block;
			width: 100%;
			height: 5px;
			position: absolute;
			left: 0;
			bottom: 0;
			background: $white;
			opacity: .1;
		}

		.fas {
			padding-left: 4rem;

			@media (max-width: 1024px) {
				padding-left: 1rem;
			}

			@include media-breakpoint-down(md) {
				padding-left: 2rem;
			}

			@include media-breakpoint-down(sm) {
				margin-left: auto;
				padding-left: .5rem;
			}

			@media (max-width: 576px) {
				font-size: calculateRem(12);
			}
		}
	}

	.header-contact-widget {
		display: none;
		position: relative;
		font-size: calculateRem(14);

		@media (min-width: 1024px) {
			display: inline-block;
		}

		a {
			color: $black;
		}

		.fas {
			color: $green-1;
			font-size: calculateRem(12);
			position: absolute;
			left: .5rem;
			top: 4px;
		}

		&__title {
			position: relative;
			padding-left: 2rem;
			font-weight: 500;
			padding-bottom: .25rem;
		}

		&__phone {
			position: relative;
			padding-left: 2rem;
			padding-bottom: .25rem;

			.fas {

			}

		}

		&__email {
			position: relative;
			padding-left: 2rem;
		}
	}

}