.companies-list {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;

	&__item {
		border: 1px solid $grey-1;
		margin-left: 1rem;
		margin-right: 1rem;
		margin-bottom: 2rem;
	}
}

.company-vcard {
	padding-top: .5rem;
	padding-bottom: .5rem;
	padding-right: 1rem;
	position: relative;

	&__content-col {
		padding-right: 2rem !important;

		@media (max-width: 768px) {
			padding-top: 2rem;
			padding-left: 2rem !important;
			padding-right: 2rem !important;
			padding-bottom: 2rem;
		}

		@media (min-width: 1280px) {
			padding-right: 3rem !important;
		}
	}


	&__title {
		color: $black;
		font-size: calculateRem(16);
		margin-top: .5rem;
		margin-bottom: 1rem;
		max-width: 90%;

		a {
			color: inherit;
		}
	}

	&_address {

	}

	&__category, &__tags {
		color: $black;
		font-size: calculateRem(15);
		margin-top: .75rem;
		margin-bottom: .75rem;
		display: block;
		text-align: justify;

		a {
			color: inherit;
		}

		strong {
			color: $green-3;
		}
	}

	&__logo {
		margin-left: -.5rem;
		position: relative;
		height: 100%;


		img {
			max-width: 100%;
			height: auto;
			position: relative;
		}
	}

	&__image {
		position: relative;

		&:after {
			content: ' ';
			position: absolute;
			bottom: -5px;
			left: 0;
			width: 91px;
			height: 5px;
			background: $green-1;
		}
	}

	&__more {
		@extend .btn-green;

		font-size: calculateRem(15);
		margin-left: auto;
		display: inline-block;

		@media (min-width: 1024px) {
			position: absolute;
			bottom: 0;
			left: 0;
		}

		.fas {
			font-size: calculateRem(12);
			padding-left: 2rem;

			@media (min-width: 1440px) {
				padding-left: 4rem;
			}
		}
	}

	&__badge {
		@extend .btn-green;

		font-size: calculateRem(13);
		text-transform: uppercase;
		padding: .5rem 1.5rem;
		box-shadow: 0px 0px 8px 4px rgba(127,181,47,.71);
		float: right;
		
		@media (min-width: 1280px) {
			position: absolute;
			right: -1.75rem;
			top: .5rem;
			float: none;
		}

		@include media-breakpoint-down(md) {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
}

.paginator {
	padding-left: 1.5rem;
	padding-right: 1rem;

	a {
		color: $black;
		font-weight: 400;
		font-size: calculateRem(13);
		min-width: 32px;
		// min-height: 36px;
		padding: 0px 8px;
		box-sizing: border-box;
		display: inline-block;
		text-align: center;
		line-height: 36px;
		vertical-align: middle;
		position: relative;
		border-radius: 5px;
		margin-right: .5rem;
		border: 1px solid $grey-1;
		margin-bottom: .5rem;

		&.current {
			color: $white;
			background: $green-1;
			font-weight: 500;
			font-size: calculateRem(15);
			border-color: $green-1;
		}

		&:hover, &:focus {
			color: $white;
			background: $black;
			text-decoration: none;
		}
	}

	.go-up {
		cursor: pointer;
		width: 45px;
		height: 36px;
		color: $white;
		background: $green-1;
		margin-right: auto;
		line-height: 36px;
		text-align: center;
		border-radius: 8px;
		display: inline-block;
		float: right;

		.fas {
			font-size: calculateRem(16);
		}
	}
}

.trade-list-button {
	color: $green-3;
	font-size: calculateRem(16);
	font-weight: 400;
	position: relative;
	margin-bottom: 0rem;
	cursor: pointer;
	padding-left: 3rem;

	@media (min-width: 1280px) {
		padding-left: 5rem;
	}

	@include media-breakpoint-down(md) {
		padding-left: 1.25rem;
	}

	&:before {
		// color: $green-1;
	    // font-family: 'Font Awesome 5 Free';
	    // font-weight: 900;
		// content: '\f067';
		// font-size: calculateRem(16);
		content: ' ';
		left: 1rem;
		top: 50%;
		transform: translateY(-50%);
		position: absolute;
		width: 12px;
		height: 2px;
		background-image: url('../images/minus-icon.png');

		@media (min-width: 1280px) {
			left: 3rem;
		}

		@include media-breakpoint-down(md) {
			left: .25rem;
		}
	}
	
	&.collapsed {
		color: $black;
		margin-bottom: 1rem;

		&:before {
			width: 12px;
			height: 12px;
			background-image: url('../images/plus-icon.png');
		}
	}

}

.trade-list-content {
	padding-left: 0rem;

	@media (min-width: 1280px) {
		padding-left: 4rem;
	}

	@include media-breakpoint-down(md) {
		padding-left: 0;
	}

	.trade-branch-list {
		font-size: calculateRem(14);
		list-style-type: none;
		padding-left: 2rem;
		margin-bottom: 0;
		padding-top: .5rem;
		padding-bottom: .75rem;
		
		@include media-breakpoint-down(md) {
			padding-left: .5rem;
		}

		li {
			position: relative;
			padding-left: 1.5rem;
			line-height: 1.8;

			@include media-breakpoint-down(md) {
				padding-left: .75rem;
				padding-top: .5rem;
				padding-bottom: .5rem;
				line-height: 1;
			}

			&:before {
				// color: $green-1;
			    // font-family: 'Font Awesome 5 Free';
			    // font-weight: 900;
				// content: '\f067';
				// font-size: calculateRem(16);
				content: ' ';
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				position: absolute;
				width: 6px;
				height: 2px;
				background: $green-3;

				@include media-breakpoint-down(md) {
					// display: none;
				}
			}

			&.current {
				color: $green-1;
				font-weight: 500;
			}
		}

		a {
			color: inherit;
		}
	}
}