@function calculateRem($sizeF) {
    $remSize: $sizeF / 16;

    @return #{$remSize}rem;
}

@mixin placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}
