.section {

	&--main-content {
		padding-bottom: 2rem;
		margin-bottom: 2rem;

		&.section-vcard {
			margin-top: 2rem;
		}


		@media (max-width: 576px) {
			padding-bottom: 1rem;
			margin-bottom: 1rem;
		}
	}
	
	.text-content {
		a {
			color: $green-1;
		}
	}
	
	&__header {
		color: $black;
		font-weight: 300;
		font-size: calculateRem(26);
		border-bottom: 1px solid $grey-1;
		position: relative;
		padding-bottom: .5rem;
		margin-bottom: 3rem;
		display: flex;
		align-items: center;

		&--fw400 {
			font-weight: 400;
		}


		@include media-breakpoint-down(md) {
			font-size: calculateRem(22);
		}

		&--with-link {
			display: flex;
			align-items: center;
			flex-direction: row;
			width: 100%;
			justify-content: space-between;
		}

		&:after {
			content: ' ';
			display: block;
			width: 50px;
			height: 5px;
			background-color: $green-1;
			left: 0;
			bottom: -5px;
			position: absolute;
		}

		&__link, .addthis_button{
			color: $green-1;
			font-size: calculateRem(14);
			margin-left: auto;

			@media (max-width: 1024px) {
				// display: block;
				// width: 100%;
			}

			&:hover, &:focus {
				color: $black;
				text-decoration: none;
			}

			.fas {
				font-size: calculateRem(10);
				margin-left: .25rem;
			}
		}
	}

	&__subheader {
		color: $green-3;
		font-size: calculateRem(20);
	}

	&__widget {
		border-top: 1px solid $grey-1;
		border-right: 1px solid $grey-1;
		border-bottom: 5px solid $green-1;
		border-left: 1px solid $grey-1;
		// height: 100%;
		padding: 2rem 1.75rem 2rem 3rem;
		box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.05);
		margin-bottom: 2rem;
		font-size: calculateRem(14);

		@media (max-width: 1024px) {
			padding: 1.5rem 1rem;
		}

		.section__header {
			margin-bottom: 2rem;
		}
	}
}

.company-list {
	
	$self: &;

	&__item {
		margin-bottom: 2rem;

		&__inner {
			border-top: 1px solid $grey-1;
			border-bottom: 1px solid $grey-1;
			border-right: 1px solid $grey-1;
			border-left: 5px solid $green-1;
			height: 100%;
			padding: 2rem 2rem;

			&:after {
				color: $green-1;
				font-family: 'Font Awesome 5 Free';
			  	font-weight: 900;
				content: '\f054';
				display: block;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 1.5rem;
				opacity: 0;

			    @include transition-property(opacity);

			    @include transition-duration(0.25s);

			    @include transition-timing-function(ease-in-out);
			}

		    @include transition-property(box-shadow);

		    @include transition-duration(0.25s);

		    @include transition-timing-function(ease-in-out);

			&:hover {
				box-shadow: 0px 0px 20px 10px rgba(0,0,0,0.15);

				&:after {
					opacity: 1;
				}
			}

			> .row {
				height: 100%;
			}
		}


		&__title {
			color: $black;
			font-weight: 500;
			font-size: calculateRem(16);
			margin-bottom: 1rem;

			a {
				color: inherit;
			}
		}

		&__desc {
			color: $green-1;
			font-weight: 300;
			font-size: calculateRem(14);

			a {
				color: inherit;
			}
		}

		&__logo {
			max-width: 100%;
			height: auto;
			max-height: 100px;
			text-align: right;
		}
	}
}

.recommended-company {
	padding: 0;
	margin: 0 0 3rem 0;
	display: flex;
	list-style-type: none;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	li {
		padding: 0 1rem;

		&:hover {
			img {
				opacity: 1;
			}
		}

		img {
			max-height: 75px;
			max-width: 180px;
			height: auto;
			opacity: .75;

		    @include transition-property(opacity);

		    @include transition-duration(0.5s);

		    @include transition-timing-function(ease-in-out);
		}
	}
}

.last-company {
	padding: 0;
	margin: 0 0 3rem 0;
	list-style-type: none;		
	// display: flex;
	// flex-wrap: wrap;
	@include media-breakpoint-down(md) {
		margin-bottom: 1rem;
	}

	&__item {
		padding: .5rem 0;
		color: $black;
		font-size: calculateRem(14);
		
		&:not(:last-child) {
			border-bottom: 1px dotted $grey-1;
		}
		
		a {
			color: inherit;

			&:hover, &:focus {
				text-decoration: none;
				color: $green-1;
			}
		}
	}
}

.company-fee-baner {
	text-align: center;
	width: 100%;
	background: $grey-1;
	margin: 1rem 0 5rem 0;
	padding: 1rem;
	box-sizing: border-box;
}

.tag {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	margin: 0;
	padding: 0;

	&__item {
		margin: 0.25rem;

		a {
			color: $white;
			font-size: calculateRem(16);
			background: $green-1;
			box-sizing: border-box;
			padding: .85rem 1.2rem;
			display: block;
			border-radius: 8px;

		    @include transition-property(all);

		    @include transition-duration(0.25s);

		    @include transition-timing-function(ease-in-out);

			&:hover, &:focus {
				text-decoration: none;
				background: $black;
			}

			@include media-breakpoint-down(md) {
				font-size: calculateRem(14);	
				padding: .85rem .85rem;
			}
		}
	}
}



.info-grupa-join {
	text-align: center;
	margin-top: 3rem;
	display: block;
	padding-bottom: 1rem;
	display: inline-flex;
	flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

	&__title {
		color: $green-1;
		font-weight: 500;
		font-size: calculateRem(26);
		line-height: 1;
		display: inline-block;

		@media (max-width: 1024px) {
			font-size: calculateRem(22);
		}

		@media (min-width: 1025px) and (max-width: 1366px) {
			font-size: calculateRem(24);
		}
	}

	&__subtitle {
		color: $black;
		font-weight: 500;
		font-size: calculateRem(16);
		line-height: 1;
		display: inline-block;
	}

	&__button {
		@extend .btn;

		// cursor: pointer;
		color: $white !important;
		font-weight: 500;
		font-size: calculateRem(16);
		background: $green-2;
		padding: 1.063rem 1.5rem 1.063rem 1rem;
		position: relative;
		border-radius: 5px;
		// margin-left: 1.25rem;
		position: relative;
		box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, .15);
		margin-top: 2rem;
		

	    display: inline-flex;
	    align-items: center;
	    justify-content: space-between;
	    margin-left: auto;
	    margin-right: auto;

		&:hover,&:focus {
			text-decoration: none;
			color: $white;
			background: $black;
		}

		.fas {
			padding-left: 4rem;
			float: right;
			font-size: calculateRem(14);
		}
	}
}

.custom-list {
	list-style-type: none;
	padding: 0;
	margin: 0;

	$self: &;

	&__header {
		display: block;
	}

	&__content {
		display: block;
	}

	&--plus {

		li {
			position: relative;
			padding-left: 2rem;
			margin-bottom: 1rem;

			&:before {
				color: $green-1;
				content: '\f067';
				font-family: 'Font Awesome 5 Free';
			  	font-weight: 900;
			  	position: absolute;
			  	left: 0;
			  	top: 0;
			}

			a {
				color: inherit;
			}
		}
	}

	&--history {
		display: block;
		position: relative;
		padding-top: 1rem;
		padding-bottom: 1rem;
		margin-bottom: 1rem;

		&:after {
			background-color: $grey-1;
			height: 100%;
			width: 1px;
			content: ' ';
			position: absolute;
			left: 6px;
			top: 2px;
			z-index: 1;
		}
		
		li {
			position: relative;
			padding-left: 2rem;
			margin-bottom: 1.5rem;


			#{ $self }__header {
				color: $green-1;
				font-size: calculateRem(16);
				font-weight: 500;
			}

			#{ $self }__content {
				display: block;
				font-size: calculateRem(14);
				font-weight: 400;
			}

			&:before {
				color: $green-1;
				content: '\f111';
				font-family: 'Font Awesome 5 Free';
				font-size: calculateRem(13);
			  	font-weight: 900;
			  	position: absolute;
			  	left: 0;
			  	top: 2px;
			  	z-index: 2;
			}
		}
	}

	&--offer {
		margin-bottom: 4rem;

		@media (max-width: 576px) {
			margin-bottom: 2rem;
		}

		a {
			color: inherit;
			text-decoration: underline;
		}

		li {
			position: relative;
			padding-left: 5rem;
			padding-top: 2.5rem;
			padding-bottom: 2.5rem;
			border-bottom: 1px solid $grey-1;
			// margin-bottom: 2.5rem;

			&:before {
				content: '';
				background-image: url("../images/offer-list-icon.png");
				width: 51px;
				height: 51px;
			  	position: absolute;
			  	left: 0;
			  	top: 50%;
			  	transform: translateY(-50%);
			  	z-index: 2;
			}

			&:not(:last-child) {
			}

			&:first-child {
				padding-top: 0;
			}

			#{ $self }__header {
				color: $green-1;
				font-size: calculateRem(20);
				font-weight: 500;
				margin-bottom: .5rem;
			}

			#{ $self }__content {
				display: block;
				font-size: calculateRem(14);
				font-weight: 400;
			}
		}
	}
}

.gallery {
	padding-top: 3rem;
	padding-bottom: 3rem;
	margin-bottom: 3rem;

	@extend .row;

	&__item {
		@include make-col-ready();
		text-align: center;

		@include media-breakpoint-down(md) {
			padding-bottom: 1.5rem;
		}

		&__image {
			@extend .img-fluid;
		}

		@media (min-width: 1024px) {
			flex-direction: column;
		    flex: 0 0 20%;
		    max-width: 20%;
		    justify-content: center;
		}
	}
}