.search-info {
	font-size: calculateRem(24);
	margin-bottom: 0;
	// padding-left: 2rem;
}

.search-info-container {
	display: block;
	background: $grey-1;
	border-radius: 10px;
	padding: 2rem 2rem;
	margin-bottom: 2rem;
}