body {
}
.seciont {
	@extend .container-fluid;
}

.section-hero {
	background-image: url("../images/section-hero-bg.jpg");
	background-size: cover;
	height: 618px;
	position: relative;
	margin-bottom: 6rem;
	$self: &;

	@media (min-width: 1366px) {
		margin-bottom: 10rem;
	}

	&--small {
		height: auto;
		padding-top: 6rem;
		padding-bottom: 6rem;

		@media (min-width: 1366px) {
			margin-bottom: 5rem;
		}
	
		 #{ $self }__title, .section-hero-numbers {
		 	display: none;
		 }

	}

	&--smaller {
		height: auto;
		padding-top: 3rem;
		padding-bottom: 3rem;

		@media (min-width: 1366px) {
			margin-bottom: 5rem;
		}
	
		 #{ $self }__title, .section-hero-numbers {
		 	display: none;
		 }

	}

    @include media-breakpoint-down(md) {
        font-size: calculateRem(20);
        height: auto;
        padding-top: 4rem;
        padding-bottom: 4rem;
        background-size: cover;
    }

    @media (min-width: 992px) and (max-width: 1024px) {
        height: 500px;
    }


    > .container {
        height: 100%;
        position: relative;

		@media (min-width: 1440px) {
		    max-width: 1160px;
		}

        .row {
            height: 100%;
        }
    }

    &__title {
    	color: $white;
    	font-weight: bold;
    	font-size: calculateRem(66);
    	text-align: center;
    	margin-bottom: 4rem;

    	@include media-breakpoint-down(md) {
    		font-size: calculateRem(44);

    	}

    	@include media-breakpoint-down(sm) {
    		font-size: calculateRem(33);
    	}

    	span {
    		color: $green-1;
    	}
    }

    .form-container {
    	background: rgba(45, 45, 45, .2);
    	padding: 2rem;
    	border-radius: 10px;
    	width: 100%;
    	max-width: 1066px;
    	margin-left: auto;
    	margin-right: auto;

    	@media (max-width: 1366px ) {
    		max-width: 880px
    	}

		@include media-breakpoint-down(md) {
			padding: 1rem;
		}
}

    .searchFields {
	    @extend .row;
    	justify-content: center;
    	align-items: center;
		
		.input-group-addon {
			border: 0;
			background: $white;
			border-radius: 0;
			padding-left: 1.5rem;
		}
		
		.input-group {
			@include media-breakpoint-down(md) {
				margin-bottom: 1rem;
			}

			&.phrase {

				.search-input {
			    	
			    	@include media-breakpoint-down(md) {
						border-top-right-radius: 8px;
						border-bottom-right-radius: 8px;
			    	}
				}

				.input-group-addon {
					border-top-left-radius: 8px;
					border-bottom-left-radius: 8px;

			    	@include media-breakpoint-down(md) {
						border-top-left-radius: 8px;
						border-bottom-left-radius: 8px;
			    	}
				}
			}

			&.localization {

				.search-input {
					border-top-right-radius: 8px;
					border-bottom-right-radius: 8px;

			    	@include media-breakpoint-down(md) {
						border-top-right-radius: 8px;
						border-bottom-right-radius: 8px;
			    	}
				}

				.input-group-addon {

					@include media-breakpoint-down(md) {
						border-top-left-radius: 8px;
						border-bottom-left-radius: 8px;
					}
				}
			}

		}

		.input-group-addon {
			.fas {
				font-size: calculateRem(24);
				opacity: .2;
			}
		}

    	.search-input {
			@extend .form-control;
			min-height: 56px;
			width: 100%;
			border: none;
			border-radius: 0;



			@include placeholder {
				opacity: .5;

				@include media-breakpoint-down(md) {
					font-size: calculateRem(14);
				}
			}

			// flex: 0 0 40%;
			// max-width: 40%;
			// position: relative;
			// width: 100%;
			// min-height: 1px;
			// padding-right: 15px;
			// padding-left: 15px;
    	}
    }

    .submitsearch {
		@extend .btn;

		// cursor: pointer;
		color: $white;
		font-weight: 500;
		font-size: calculateRem(18);
		background: $green-1;
		padding: 1.063rem 1.5rem 1.063rem 1rem;
		position: relative;
		border-radius: 5px;
		// margin-left: 1.25rem;
		position: relative;
		width: 100%;

	    display: flex;
	    align-items: center;
	    justify-content: space-between;

		&:hover,&:focus {
			text-decoration: none;
			color: $white;
			background: $black;
		}

		&:after {
			content: ' ';
			display: block;
			width: 25%;
			height: 100%;
			position: absolute;
			right: 0;
			top: 0;
			background: $white;
			opacity: .1;
		}

		.fas {
			padding-left: 4rem;
			float: right;
			font-size: calculateRem(14);

			@include media-breakpoint-down(md) {
				padding-left: 1.25rem;
			} 
		}
    }

    &-numbers {
    	color: $white;
    	background-color: $green-1;
    	background-image: url("../images/hero0numbers-bg.png");
    	background-position: center;
    	background-repeat: no-repeat;
    	position: relative;
    	z-index: 2;
    	// position: absolute;
    	margin-top: -63px;
    	// left: 50%;
    	// transform: translateX(-50%) ;
    	width: 100%;
    	box-shadow: 0px 20px 40px -20px rgba(0,0,0,0.75);
    	font-size: calculateRem(22);
    	padding: 2rem;
    	display: flex;
    	justify-content: center;
    	flex-wrap: wrap;
    	border-radius: 15px;
    	overflow: hidden;
    	max-width: 100%;

		@media (max-width: 1024px) {

		}

    	@include media-breakpoint-down(md) {
    		position: relative;
    		bottom: auto;
    		padding: 1rem;    		
    		background-image: none;
    		margin-top: 4rem;
    		width: auto;
    		display: inline-block;
    		margin-left: auto;
    		margin-right: auto;	
    		left: 50%;
    		transform: translateX(-50%);
    	}

    	@include media-breakpoint-down(sm) {
    		flex-direction: column;
    		position: relative;
    		left: auto;
    		bottom: auto;
    		padding: 1rem;
    		transform: none;
    		background-image: none;
    		margin-top: 1rem;
    		display: flex;
    	}


    	&__item {
			flex: 0 0 50%;
			max-width: 50%;
			position: relative;
			width: 100%;
			min-height: 1px;
			padding-right: 15px;
			padding-left: 15px;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;

	    	@include media-breakpoint-down(md) {
				flex: 0 0 100%;
				max-width: 100%;
				margin-top: 1rem;
				margin-bottom: 1rem;
	    	}

	    	@include media-breakpoint-down(sm) {
				flex-direction: column;
	    	}

			&-icon {
				margin-right: 1rem;

				@include media-breakpoint-down(sm) {
					order: 1;
					margin-right: 0;
					margin-bottom: .5rem;
				}
			}

			&-title {

				@media (max-width: 1024px) {
					order: 3;
				}

				@include media-breakpoint-only(md) {
					// font-size: calculateRem(18);
				}
				
				@include media-breakpoint-down(sm) {
					order: 3;
				}
			}

			&-numbers {
				font-size: calculateRem(42);
				font-weight: bold;
				margin-left: 1.5rem;

				@media (max-width: 1024px) {
					order: 2;
					font-size: calculateRem(28);
					margin-left: 0;
					margin-right: .5rem;
				}

				@include media-breakpoint-down(md) {
					font-size: calculateRem(28);
					margin-left: .5rem;
				}

		    	@include media-breakpoint-down(sm) {
					font-size: calculateRem(24);
					order: 2;
					margin-left: 0;
		    	}
			}
    	}
    }
}

.scroll_item {
	display: flex;
	align-items: center;
	// justify-content: center;
	
	a {
		color: $green-1;
	}

	h2 {
		font-size: 18px;
	}
	
	h3 {
		padding:0 0 5px 0;
		font-size:12px;
		color:#000;
	}

	h3 span {
		color:#547605;
	}
	
	p {
		font-size:14px;
		// font-family:Verdana, sans-serif;
		padding:0 0 5px 0;
		margin-bottom: 0;
	}
}