.section-contact {
	.form {
		label {
			font-size: calculateRem(14);
			font-weight: bold;
			padding: 0 .5rem;
		}

		.form-control {
			padding: 1rem .75rem;
			border-radius: 8px;
		}

		.form-group {
			margin-bottom: 2rem;
		}

		.form-submit {
			@extend .btn;

			color: $white;
			font-weight: 500;
			font-size: calculateRem(16);
			background: $green-1;
			padding: 1.063rem 1.5rem 1.063rem 1rem;
			position: relative;
			border-radius: 5px;
			// margin-left: 1.25rem;
			position: relative;
		    display: flex;
		    align-items: center;
		    justify-content: space-between;
		    margin-left: auto;

			&:hover,&:focus {
				text-decoration: none;
				color: $white;
				background: $black;
			}

			&:after {
				content: ' ';
				display: block;
				width: 100%;
				height: 5px;
				position: absolute;
				left: 0;
				bottom: 0;
				background: $white;
				opacity: .1;
			}

			@media (max-width: 576px) {
				margin-bottom: 3rem;
				margin-left: auto;
				margin-right: auto;
			}

			.fas {
				// margin-right: auto;
				margin-left: 1rem;
				
				@include media-breakpoint-up(md) {
					margin-left: 2.5rem;
				}
			}
		}

        .form-rules {
            color: #a3a3a3;
            font-size: calculateRem(14);
            position: relative;

            label {
            	margin-bottom: 0;
            }

            input[type=checkbox] {
            	opacity: 0;
			    position: absolute;
			    left: 1.5rem;
			    top: .25rem;
            }

            input[type=checkbox] + label {
                padding-left: 60px;
                font-weight: 400;
                
                a {
					color: $green-1;
					text-decoration: underline;
                }
            }

            input[type=checkbox] + label:before {
                left: 1rem;
                position: absolute;
                content: "";
                display: block;
                float: left;
                width: 28px;
                height: 28px;
                vertical-align: middle;
                margin-right: 8px;
                background-color: transparent;
                border: 1px solid $grey-1;
                border-radius: 8px;
                margin-right: 15px;
                top: 50%;
                transform: translateY(-50%);
            }

            input[type=checkbox]:checked + label:before {
                content: "\2714";
                color: $green-1;
                font-size: calculateRem(28);
                text-align: center;
                line-height: 28px;
            }
        }
	}
}

.contact-list {
	padding: 0 0 3rem 0;
	margin: 0;
	list-style-type: none;
	position: relative;

	a {
		color: inherit;

		&:hover, &:focus {
			text-decoration: none;
		}
	}

	&__item {
		padding-bottom: 0.25rem;
		padding-left: 4rem;
		position: relative;
		
		&:not(:first-child) {
			padding-top: 1.5rem;
		}
		
		&:not(:last-child) {
			border-bottom: 1px solid $grey-1;
		}

		&__title {
			color: $green-3;
			font-size: calculateRem(16);
			margin-bottom: 1rem;
		}

		&__phone,
		&__address,
		&__mail,
		&__info {
			position: relative;
			margin-bottom: 1rem;
			font-size: calculateRem(14);

			&:before {
				content: ' ';
				display: block;
				width: 19px;
				height: 17px;
				position: absolute;
				left: -3rem;
				top: 0px;
				background-image: url("../images/address-green-icon.png");
			}
		}

		&__address {

			&:before {
				width: 19px;
				height: 17px;
				background-image: url("../images/address-green-icon.png");
			}
		}

		&__phone {

			&:before {
				width: 19px;
				height: 19px;
				background-image: url("../images/phone-2-green-icon.png");
			}
		}

		&__mail {

			&:before {
				width: 19px;
				height: 19px;
				background-image: url("../images/click-green-icon.png");
			}
		}

		&__info {

			&:before {
				width: 19px;
				height: 19px;
				background-image: url("../images/info-green-icon.png");
			}
		}
	}
}